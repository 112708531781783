import PropTypes from 'prop-types'
import {keys, isObject} from 'lodash-es'
import styled, {css} from 'styled-components'
import theme from '../../../theme/theme'
import {media} from '../../../utils'


const {components: {typography: {weight, variants, fonts: {heading, text}}}} = theme

const baseStyles = css`
  strong {
    font-weight: ${weight.bold};
  }
`

const chooseFontSize = (variant) => {
  if (isObject(variant)) {
    return css`
      font-size: ${variant.desktop};

      ${media.down('mobile')(css`
        font-size: ${variant.mobile};
      `)}
    `
  }

  return css`
    font-size: ${variant};
  `
}

const baseTextStyles = css`
  strong {
    font-weight: ${weight.bold};
  }

  em {
    font-style: italic;
  }

  ul {
    list-style-position: inside;
  }

  li {
    &::before {
      padding-right: 0.5rem;
      text-indent: 5px;
      color: ${theme.colors.primary};
      content: '—';
    }
  }

  a {
    text-decoration: underline;
  }

  p {
    line-height: 2rem;
  }

  h1 {
    font-weight: ${weight.bold};
    ${chooseFontSize(variants.h1)}
  }

  h2 {
    line-height: normal;
    font-weight: ${weight.bold};
    ${chooseFontSize(variants.h2)}
  }

  h3 {
    font-weight: ${weight.bold};
    ${chooseFontSize(variants.h3)}
  }

  h4 {
    font-weight: ${weight.bold};
    ${chooseFontSize(variants.h4)}
  }

  h5 {
    font-weight: ${weight.bold};
    ${chooseFontSize(variants.h5)}
  }

  h6 {
    font-weight: ${weight.bold};
    ${chooseFontSize(variants.h6)}
  }
`

const types = {
  p: css`
    line-height: 2;
    letter-spacing: normal;
    font-family: ${text};
  `,
  h1: css`
    line-height: 1.5;
    letter-spacing: normal;
    font-family: ${heading};
    font-weight: ${weight.bold};
  `,
  h2: css`
    line-height: 3.25rem;
    letter-spacing: normal;
    font-family: ${heading};
    font-weight: ${weight.bold};
  `,
  h3: css`
    line-height: 1;
    letter-spacing: normal;
    font-family: ${heading};
    font-weight: ${weight.bold};
  `,
  h4: css`
    line-height: 1;
    letter-spacing: normal;
    font-family: ${heading};
    font-weight: ${weight.bold};
  `,
  h5: css`
    line-height: 1.5;
    letter-spacing: normal;
    font-family: ${heading};
    font-weight: ${weight.bold};
  `,
  h6: css`
    line-height: 1.875rem;
    letter-spacing: normal;
    font-family: ${heading};
    font-weight: ${weight.bold};
  `,
  div: css`
    ${baseTextStyles};
    line-height: 2;
    font-family: ${text};
  `,
  span: css`
    line-height: 2;
    letter-spacing: normal;
  `,
}

const StyledText = styled('p').withConfig({shouldForwardProp: (prop, defaultValidatorFn) => (
  !['type'].includes(prop) && defaultValidatorFn(prop)
)})`
  ${({type}) => types[type]}
  ${({type}) => chooseFontSize(variants[type])}

  ${({align}) => align && css`
    text-align: ${({align}) => align};
  `}

  ${({thin}) => thin && css`
    font-weight: ${weight.light};
  `}

  ${({bold}) => bold && css`
    font-weight: ${weight.bold};
  `}

  ${({uppercase}) => uppercase && css`
    text-transform: uppercase;
  `}

  ${({gutter}) => gutter && css`
    padding-bottom: 1rem;
  `}

  ${({color}) => color && css`
    color: ${({color}) => theme.colors[color]};
  `}

  ${({srOnly}) => srOnly && css`
    position: absolute;
    top: 0;
    left: 0;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
  `}

  ${baseStyles}
`

const Typography = ({
  variant, type, align, isHeading, uppercase, gutter, color, srOnly, thin, bold, children, ...props
}) => {
  return (
    <StyledText
        as={variant || type}
        type={type}
        align={align}
        isHeading={isHeading}
        uppercase={uppercase}
        gutter={gutter}
        color={color}
        srOnly={srOnly}
        thin={thin}
        bold={bold}
        dangerouslySetInnerHTML={{__html: children}}
        {...props}
    />
  )
}

Typography.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.oneOf([...keys(variants)], 'base'),
  align: PropTypes.string,
  isHeading: PropTypes.bool,
  uppercase: PropTypes.bool,
  gutter: PropTypes.bool,
  color: PropTypes.string,
  srOnly: PropTypes.bool,
  thin: PropTypes.bool,
  bold: PropTypes.bool,
  children: PropTypes.node,
}

Typography.defaultProps = {
  isHeading: false,
  uppercase: false,
  srOnly: false,
  thin: false,
  gutter: false,
  type: 'p',
}

export default Typography
