import {useState, useEffect} from 'react'
import {split} from 'lodash-es'
import {navigate} from 'gatsby'
import {getLs, setLs} from '../localStorage'
import {languages} from '../constants'


const useLang = (pageLang, langToSlug) => {
  if (typeof window !== 'undefined') {
    const [lang, setLang] = useState(pageLang)
    const [browserLang] = split(window.navigator.language, '_')

    const setLanguage = (lang) => {
      setLang(lang)
      setLs('lang', lang)
    }

    useEffect(() => {
      const lsLang = getLs('lang')
      if (lsLang === pageLang) return

      if (!lsLang && languages[browserLang] && browserLang !== pageLang) {
        navigate(langToSlug[browserLang])
      }

      return setLanguage(pageLang)
    }, [pageLang, browserLang, lang])

    return {lang, setLanguage}
  }

  return {lang: pageLang}
}

export default useLang
