import React from 'react'
import PropTypes from 'prop-types'
import {isEmpty} from 'lodash-es'
import styled, {css} from 'styled-components'
import {If, Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import {media} from '../../../utils'
import HellitLogo from '../../../assets/logos/logo.svg'
import {ArrowIcon} from '../../../assets/icons'
import BaseLink from '../../atoms/BaseLink'
import Typography from '../../atoms/Typography'
import Menu from '../Menu'
import LanguageSwitcher from './LanguageSwitcher'


const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 2.5rem 3rem;

  ${media.down('mobile')(css`
    padding: 1.75rem;
  `)}
`

const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
`

const StyledLogo = styled.img`
  width: 6.25rem;

  ${media.down('mobile')(css`
    width: 8.25rem;
  `)}
`

const StyledArrow = styled(ArrowIcon)`
  padding: 0 0.75rem 0.25rem 0;
  width: 2rem;
  height: 2rem;
  vertical-align: middle;

  ${media.down('mobile')(css`
    display: none;
  `)}
`

const StyledTitle = styled(Typography)`
  display: inline-block;
  position: relative;

  ${media.down('mobile')(css`
    display: none;
  `)}
`

const Navbar = ({
  title, titleLink, currentLang, langToSlug, submenu, isMenuActive, onMenuClick, children, ...props
}) => {
  const baseLinkTo = currentLang === 'en' ? '/en/' : '/'

  return (
    <StyledHeader {...props}>
      <StyledHeaderWrapper>
        <BaseLink to={baseLinkTo} rel="noopener noreferrer">
          <StyledLogo loading="lazy" src={HellitLogo} alt="Logo Hellit" />
        </BaseLink>
        <If condition={!isEmpty(title)}>
          <div>
            <Choose>
              <When condition={!isEmpty(titleLink)}>
                <BaseLink to={titleLink}>
                  <StyledArrow />
                  <StyledTitle variant="span" type="h6" withArrow={!isEmpty(titleLink)}>{title}</StyledTitle>
                </BaseLink>
              </When>
              <Otherwise>
                <StyledTitle variant="h1" type="h6">{title}</StyledTitle>
              </Otherwise>
            </Choose>
          </div>
        </If>
        <LanguageSwitcher
            currentLang={currentLang}
            isMenuActive={isMenuActive}
            onMenuClick={onMenuClick}
            langToSlug={langToSlug}
        />
      </StyledHeaderWrapper>
      <Menu active={isMenuActive} submenu={submenu} onBackdropClick={onMenuClick}>
        {children}
      </Menu>
    </StyledHeader>
  )
}

Navbar.propTypes = {
  title: PropTypes.string,
  titleLink: PropTypes.string,
  currentLang: PropTypes.string,
  langToSlug: PropTypes.object,
  submenu: PropTypes.array,
  isMenuActive: PropTypes.bool,
  onMenuClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default Navbar
