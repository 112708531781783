import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {If, For} from 'babel-plugin-jsx-control-statements'
import {isEmpty} from 'lodash-es'
import {navigate} from 'gatsby'
import navigationData from '../../../../content/settings/navigation.json'
import useScrollBlock from '../../../hooks/useScrollBlock'
import NavItem from '../../molecules/Menu/NavItem'
import NavWrapper from '../../molecules/Menu/NavWrapper'
import VisualNavbar from '../../molecules/Navbar'
import NestedItemsWrapper from '../../molecules/Menu/NestedItemsWrapper'


const initialSubmenuState = {
  to: '',
  items: [],
}

const Navbar = ({title, titleLink, lang, langToSlug}) => {
  const [isMenuActive, setMenuActive] = useState(false)
  const [submenu, setSubmenu] = useState(initialSubmenuState)
  const [blockScroll, allowScroll] = useScrollBlock()
  const navigation = navigationData[lang]

  const handleEscPress = (e) => {
    if (e.keyCode === 27) {
      setSubmenu(initialSubmenuState)
      setMenuActive(false)
    }
  }

  useEffect(() => {
    if (isMenuActive && typeof document !== undefined) {
      document.addEventListener('keydown', handleEscPress, false)

      return () => {
        allowScroll()
        document.removeEventListener('keydown', handleEscPress, false)
      }
    }
  }, [isMenuActive, handleEscPress])

  const openMenu = () => {
    blockScroll()
    setMenuActive(true)
  }

  const closeMenu = () => {
    allowScroll()
    setSubmenu(initialSubmenuState)
    setMenuActive(false)
  }

  const handleMenuClick = () => {
    if (typeof window !== undefined) window.scrollTo(0, 0)
    if (isMenuActive) {
      return closeMenu()
    }
    openMenu()
  }

  const handleSubmenuClick = (to, items) => () => {
    if (to === submenu.to) {
      closeMenu()
      return navigate(to)
    }
    setSubmenu({to, items})
  }

  return (
    <VisualNavbar
        title={title}
        titleLink={lang === 'en' && !isEmpty(titleLink) ? `/${lang}${titleLink}` : titleLink}
        currentLang={lang}
        langToSlug={langToSlug}
        submenu={submenu.items}
        isMenuActive={isMenuActive}
        onMenuClick={handleMenuClick}
    >
      <NavWrapper>
        <For each="link" of={navigation?.links} index="idx">
          <NavItem
              key={idx}
              to={link.link}
              onClick={link?.submenu && handleSubmenuClick(link.link, link.submenu)}
          >
            {link.text}
            <If condition={link?.submenu || link?.index}>
              <sup>{link?.index || link?.submenu?.length}</sup>
            </If>
          </NavItem>
          <If condition={submenu.to === link.link}>
            <NestedItemsWrapper>
              <For each="link" of={link?.submenu} index="idx">
                <NavItem key={idx} to={link.link} nested>{link.text}</NavItem>
              </For>
            </NestedItemsWrapper>
          </If>
        </For>
      </NavWrapper>
      <NavWrapper>
        <For each="link" of={submenu?.items} index="idx">
          <NavItem key={idx} to={link.link} nested>{link.text}</NavItem>
        </For>
      </NavWrapper>
    </VisualNavbar>
  )
}

Navbar.propTypes = {
  title: PropTypes.string,
  titleLink: PropTypes.string,
  lang: PropTypes.string,
  langToSlug: PropTypes.object.isRequired,
}

export default Navbar
