import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import cookiesData from '../../../../content/settings/cookies.json'
import {convertMDContent, media} from '../../../utils'
import Typography from '../../atoms/Typography'


const StyledBar = styled.div`
  position: fixed;
  top: 100%;
  transform: ${({isVisible}) => isVisible ? 'translateY(-100%)' : 'translateY(0)'};
  transition: transform 0.3s ease-in;
  z-index: 9999;
  background-color: ${({theme}) => theme.colors.secondary};
  padding: 2rem 6.5rem;
  width: 100%;
  max-width: 100vw;
  color: ${({theme}) => theme.colors.textInverted};

  ${media.down('mobile')(css`
    flex-flow: column;
    margin-right: 0;
    padding: 1.25rem 0.625rem;
    text-align: center;
  `)}
`

const StyledText = styled(Typography)`
  padding: 0.5rem 0 1.5rem;

  a {
    color: ${({theme}) => theme.colors.textInverted};
  }
`

const StyledButtonsContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;

  button {
    &:not(:last-child) {
      margin-right: 1.75rem;
    }
  }

  ${media.down('mobile')(css`
    flex-flow: column;
    width: 100%;

    button {
      &:not(:last-child) {
        margin: 0 0 1.75rem 0;
      }

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  `)}
`

const StyledButton = styled.button`
  border: 1px solid ${({theme}) => theme.colors.background};
  border-radius: 3px;
  background: ${({theme}) => theme.colors.background};
  cursor: pointer;
  padding: 1.25rem 2.5rem;
  color: ${({theme}) => theme.colors.text};
  font-size: 0.875rem;
  font-weight: ${({theme}) => theme.components.typography.weight.semiBold};
`

const CookieBar = ({lang, visible, onAllow, onReject, ...props}) => {
  const cookies = cookiesData[lang]

  return (
    <StyledBar isVisible={visible} {...props}>
      <Typography variant="h2" type="h6">{cookies.title}</Typography>
      <div>
        <StyledText type="div">
          {convertMDContent(cookies.description)}
        </StyledText>
        <StyledButtonsContainer>
          <StyledButton onClick={onAllow}>{cookies.allowButton}</StyledButton>
          <StyledButton onClick={onReject}>{cookies.rejectButton}</StyledButton>
        </StyledButtonsContainer>
      </div>
    </StyledBar>
  )
}

CookieBar.propTypes = {
  lang: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onAllow: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
}

export default CookieBar
