import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from '../../../theme/theme'
import BaseLink from '../../atoms/BaseLink'


const StyledContactLink = styled(BaseLink)`
  display: block;
  line-height: 2;
  color: ${theme.colors.textInverted};

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const FooterContactLink = ({href, children, ...props}) => {
  return (
    <StyledContactLink to={href} {...props} isExternal>{children}</StyledContactLink>
  )
}

FooterContactLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default FooterContactLink
