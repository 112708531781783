import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'
import {map} from 'lodash-es'
import ogImage from '../../../assets/placeholderOgImage.jpeg'
import {languages} from '../../../constants'


const Seo = ({title, description, lang, meta, langToSlug, seo}) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  const alternateLinks = map(languages, ({key}) => ({
    rel: 'alternate',
    hrefLang: key,
    href: site.siteMetadata.siteUrl + langToSlug[key],
  }))

  return (
    <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        defaultTitle={defaultTitle}
        link={[...alternateLinks]}
        meta={[
          {
            name: 'description',
            content: seo?.description || metaDescription,
          },
          {
            property: 'og:title',
            content: seo?.title || defaultTitle,
          },
          {
            property: 'og:description',
            content: seo?.description || metaDescription,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: seo?.ogImage.image?.publicURL || ogImage,
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:creator',
            content: site.siteMetadata?.author || '',
          },
          {
            name: 'twitter:title',
            content: seo?.title || title,
          },
          {
            name: 'twitter:description',
            content: seo?.description || metaDescription,
          },
          {
            name: 'twitter:image',
            content: seo?.ogImage.image?.publicURL || ogImage,
          },
        ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: 'cs',
  meta: [],
  description: '',
}

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  langToSlug: PropTypes.object.isRequired,
  seo: PropTypes.object,
}

export default Seo
