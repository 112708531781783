import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import theme from '../../../theme/theme'
import {media} from '../../../utils'
import BaseLink from '../../atoms/BaseLink'


const StyledLink = styled(BaseLink)`
  display: block;
  transition: opacity ${theme.global.duration} ease-in-out;
  opacity: 0.5;
  color: ${theme.colors.textInverted};

  &:hover,
  &:focus {
    opacity: 1;
  }

  ${media.down('mobile')(css`
    padding-bottom: 2rem;
  `)}
`

const FooterNavigatonLink = ({to, children, ...props}) => {
  return (
    <StyledLink to={to} $small {...props}>{children}</StyledLink>
  )
}

FooterNavigatonLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default FooterNavigatonLink
