import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {Choose, When, Otherwise} from 'babel-plugin-jsx-control-statements'
import settings from '../../../../content/settings/general.json'
import MenuIcon from '../../../assets/icons/menu-icon.svg'
import CloseIcon from '../../../assets/icons/close-icon.svg'
import Typography from '../../atoms/Typography'


const StyledMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color ${({theme}) => theme.global.duration} ease-in-out;
  border: 0;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  width: 2.8rem;
  height: 2.8rem;

  path,
  rect {
    stroke: ${({theme}) => theme.colors.secondary};
    transition: stroke ${({theme}) => theme.global.duration} ease-in-out;

    ${({active}) => active && css`
      stroke: ${({theme}) => theme.colors.textInverted};
    `}
  }

  &:hover {
    background-color: ${({theme}) => theme.colors.primary};

    path,
    rect {
      stroke: ${({theme}) => theme.colors.textInverted};
    }
  }

  &:focus {
    border: 1px solid ${({theme}) => theme.colors.primary}60;
  }
`

const MenuButton = ({active, lang, onClick, ...props}) => {
  return (
    <StyledMenuButton type="button" active={active} onClick={onClick} {...props}>
      <Choose>
        <When condition={active}>
          <Typography type="span" srOnly>{settings[lang].menu.closeMenu}</Typography>
          <CloseIcon />
        </When>
        <Otherwise>
          <Typography type="span" srOnly>{settings[lang].menu.openMenu}</Typography>
          <MenuIcon />
        </Otherwise>
      </Choose>
    </StyledMenuButton>
  )
}

MenuButton.propTypes = {
  active: PropTypes.bool,
  lang: PropTypes.string,
  onClick: PropTypes.func,
}

export default MenuButton
