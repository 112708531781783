import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'


const StyledNavigation = styled.nav`
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding-right: 0.625rem;
  width: 30%;

  ${media.down('mobile')(css`
    flex-direction: column;
    align-items: center;
    order: 1;
    padding: 5rem 0 3rem;
  `)}
`

const FooterNavigaton = ({children, ...props}) => {
  return (
    <StyledNavigation {...props}>
      {children}
    </StyledNavigation>
  )
}

FooterNavigaton.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FooterNavigaton
