import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import Typography from '../../atoms/Typography'


const StyledContactContainer = styled.div`
  padding-top: 3.125rem;

  ${media.down('mobile')(css`
    text-align: center;
    padding-top: 2.5rem;
 `)}
`

const FooterContactsSection = ({heading, children, ...props}) => {
  return (
    <StyledContactContainer {...props}>
      <Typography color="primary" variant="h3" bold>{heading}</Typography>
      {children}
    </StyledContactContainer>
  )
}

FooterContactsSection.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default FooterContactsSection
