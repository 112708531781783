import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import theme from '../../../theme/theme'
import {media} from '../../../utils'
import Typography from '../../atoms/Typography'


const StyledButton = styled.button`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border: 0;
  border-radius: 50%;
  background-color: ${theme.colors.dark};
  cursor: pointer;
  width: 10.625rem;
  height: 10.625rem;

  &::after {
    display: inline-block;
    transform: rotate(-45deg);
    transition: margin-bottom ${theme.global.duration} ease-in-out, color ${theme.global.duration} ease-in-out;
    margin-bottom: 4rem;
    border-width: 0.1rem 0.1rem 0 0;
    border-style: solid;
    width: 0.8rem;
    height: 0.8rem;
    color: ${theme.colors.textInverted};
    content: '';
  }

  &:hover,
  &:focus {
    &::after {
      margin-bottom: 5.5rem;
      color: ${theme.colors.primary};
    }
  }

  ${media.down('mobile')(css`
    &::after {
      margin-bottom: 5.5rem;
      color: ${theme.colors.primary};
    }
 `)}
`

const StyledSpan = styled(Typography)`
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -8%);
  transition: transform ${theme.global.duration} ease-in-out, opacity ${theme.global.duration} ease-in-out;
  opacity: 0;
  width: 100%;
  height: 100%;
  color: ${theme.colors.textInverted};

  ${StyledButton}:hover &,
  ${StyledButton}:focus & {
    transform: translate(-50%, 5%);
    opacity: 1;
  }

  ${media.down('mobile')(css`
    transform: translate(-50%, 5%);
    opacity: 1;
 `)}
`

const ScrollToTopButton = ({children, ...props}) => {
  const handleClick = () => {
    if (typeof window !== undefined) window.scrollTo(0, 0)
  }

  return (
    <StyledButton type="button" onClick={handleClick} {...props}>
      <StyledSpan type="span">{children}</StyledSpan>
    </StyledButton>
  )
}

ScrollToTopButton.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ScrollToTopButton
