export const languages = {
  cs: {
    key: 'cs',
    label: 'CZ',
    path: '/',
  },
  en: {
    key: 'en',
    label: 'EN',
    path: '/en/',
  },
}
