import PropTypes from 'prop-types'
import styled from 'styled-components'
import {media} from '../../../utils'


const StyledNestedItemsWrapper = styled.div`
  padding: 0 2.5rem;
  width: 100%;

  ${media.up('mobile')(`
    display: none;
  `)}
`

const NestedItemsWrapper = ({children, ...props}) => {
  return (
    <StyledNestedItemsWrapper {...props}>
      {children}
    </StyledNestedItemsWrapper>
  )
}

NestedItemsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NestedItemsWrapper
