import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {If} from 'babel-plugin-jsx-control-statements'
import theme from '../../../theme/theme'
import {media} from '../../../utils'
import Typography from '../../atoms/Typography'
import BaseLink from '../../atoms/BaseLink'
import CookieBar from '../CookieBar'
import ScrollToTopButton from './ScollToTopButton'


const StyledFooter = styled.footer`
  margin-top: auto;
  background-color: ${theme.colors.secondary};
  padding: 8.5rem 6rem 0 6rem;

  ${media.down('mobile')(css`
    padding: 0;
  `)}
`

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3rem;

  ${media.down('mobile')(css`
    flex-direction: column;
    align-items: center;
    padding: 6rem 1.5rem 0;
  `)}
`

const StyledTypography = styled(Typography)`
  width: 70%;

  ${media.down('mobile')(css`
   width: 100%;
   text-align: center;
  `)}
`

const StyledButtonContainer = styled.div`
  position: relative;
  width: 100%;
  height: 5.3rem;
  overflow: hidden;

  ${media.down('mobile')(css`
    height: 12rem;
  `)}
`

const StyledCookiesContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 0;

  ${media.down('mobile')(css`
    display: flex;
    justify-content: center;
    background-color:  ${theme.colors.dark};
    padding-top: 0.75rem;
    top: 5.3rem;
    height: 20rem;
    width: 100%;
    opacity: 1;
    z-index: 1;
 `)}
`

const StyledLinksContainer = styled.div`
  ${media.down('mobile')(css`
    display: flex;
    flex-direction: column;
 `)}
`

const StyledCookies = styled(BaseLink)`
  transition: opacity ${({theme}) => theme.global.duration} ease-in;
  opacity: 0.5;
  cursor: pointer;
  text-decoration: underline;
  line-height: 1;
  color: ${theme.colors.textInverted}80;
  font-weight: normal;

  &:not(:last-child) {
    padding-right: 1.875rem;
  }

  &:hover,
  &:focus {
    opacity: 1;
    color: ${theme.colors.textInverted};
  }

  ${media.down('mobile')(css`
    text-align: center;
    padding-bottom: 0.5rem;

    &:not(:last-child) {
      padding-right: 0;
    }
 `)}
`

const StyledCopyright = styled(Typography)`
  display: flex;
  position: absolute;
  top: 1.5rem;
  right: 0;
  opacity: 0.5;
  line-height: 1;
  color: ${theme.colors.textInverted}80;

  ${media.down('mobile')(css`
    justify-content: center;
    top: 10.5rem;
    width: 100%;
    z-index: 1;
 `)}
`

const Footer = ({message, pageUpText, copyrightText, gdprLink, cookieLinkText, conditionsFile, lang, visible,
  onAllow, onReject, onCookiesEdit, children, ...props}) => {
  return (
    <>
      <StyledFooter {...props}>
        <StyledWrapper>
          <StyledTypography color="textInverted" type="h2">{message}</StyledTypography>
          {children}
        </StyledWrapper>
        <StyledButtonContainer>
          <StyledCookiesContainer>
            <StyledLinksContainer>
              <StyledCookies small normal to={gdprLink.link}>{gdprLink.text}</StyledCookies>
              <StyledCookies small normal onClick={onCookiesEdit}>{cookieLinkText}</StyledCookies>
              <StyledCookies small normal to={conditionsFile.file} isExternal>
                {conditionsFile.fileName}
              </StyledCookies>
            </StyledLinksContainer>
          </StyledCookiesContainer>
          <ScrollToTopButton>{pageUpText}</ScrollToTopButton>
          <StyledCopyright variant="span">{copyrightText}</StyledCopyright>
        </StyledButtonContainer>
      </StyledFooter>
      <If condition={visible}>
        <CookieBar lang={lang} visible={visible} onAllow={onAllow} onReject={onReject} />
      </If>
    </>
  )
}

Footer.propTypes = {
  message: PropTypes.string.isRequired,
  pageUpText: PropTypes.string.isRequired,
  copyrightText: PropTypes.string.isRequired,
  gdprLink: PropTypes.object.isRequired,
  cookieLinkText: PropTypes.string.isRequired,
  conditionsFile: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onAllow: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onCookiesEdit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default Footer
