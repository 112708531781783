import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {Link} from 'gatsby'
import {isExternal} from '../../../utils'
import theme from '../../../theme/theme'


const StyledLink = styled.a`
  cursor: ${({isSpan}) => isSpan ? 'default' : 'pointer'};
  color: ${theme.colors.secondary};
  font-family: ${({theme}) => theme.components.typography.fonts.heading};
  font-size: ${({$small}) => $small
    ? theme.components.typography.variants.a.small
    : theme.components.typography.variants.a.normal};
  font-weight: ${({$normal}) => $normal ? '500' : theme.components.typography.weight.semiBold};

  ${({$large}) => $large && css`
    font-size: ${theme.components.typography.variants.a.large};
  `}
`

const chooseProps = (external, to) => {
  if (external) {
    return {
      href: to,
      rel: 'noopener noreferrer',
      target: '_blank',
    }
  }

  return {
    to,
    as: Link,
  }
}

const BaseLink = ({to, small, large, normal, children, isExternal: isDefaultExternal, ...props}) => {
  const external = isExternal(to) || isDefaultExternal
  if (!to) return <StyledLink as="span" $small={small} $large={large} isSpan {...props}>{children}</StyledLink>

  return (
    <StyledLink {...chooseProps(external, to)} $small={small} $large={large} $normal={normal} {...props}>
      {children}
    </StyledLink>
  )
}

BaseLink.propTypes = {
  to: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  normal: PropTypes.bool,
  isExternal: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default BaseLink
