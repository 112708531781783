import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import {media} from '../../../utils'


const StyledNav = styled.nav`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  justify-content: flex-end;
  z-index: 995;
  width: 100%;
  height: 100vh;
  pointer-events: none;
`

const MenuWrapper = styled.div`
  display: flex;
  transform: translateX(100%);
  transition: transform 200ms ease-in;
  z-index: 998;
  background-color: ${({theme}) => theme.colors.primary};
  padding: 3rem 0 0 6.25rem;
  width: 66%;
  height: 100%;
  overflow: auto;
  pointer-events: all;

  ${({active}) => active && css`
    transform: translateX(25%);

    ${media.down('mobile')(`
      transform: translateX(0);
    `)}
  `}

  ${({submenu}) => !isEmpty(submenu) && css`
    transform: translateX(0);
  `}

  ${media.down('mobile')(css`
    width: 100vw;
    padding: 3rem 0 0 3.25rem;
  `)}
`

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity 200ms ease-in;
  opacity: 0;
  z-index: 995;
  background: black;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  ${({active}) => active && css`
    opacity: 0.5;
    pointer-events: all;
  `}

  ${media.down('mobile')(`
    display: none;
  `)}
`

const Menu = ({active, onBackdropClick, submenu, children, ...props}) => {
  return (
    <>
      <StyledBackdrop active={active} onClick={onBackdropClick} />
      <StyledNav active={active} {...props}>
        <MenuWrapper active={active} submenu={submenu}>
          {children}
        </MenuWrapper>
      </StyledNav>
    </>
  )
}

Menu.propTypes = {
  active: PropTypes.bool,
  onBackdropClick: PropTypes.func,
  submenu: PropTypes.array,
  children: PropTypes.node,
}

export default Menu
