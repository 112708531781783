import PropTypes from 'prop-types'
import {map} from 'lodash-es'
import styled, {css} from 'styled-components'
import {For} from 'babel-plugin-jsx-control-statements'
import {languages} from '../../../constants'
import {media} from '../../../utils'
import BaseLink from '../../atoms/BaseLink'
import MenuButton from '../Menu/MenuButton'


const StyledLinkContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 999;
`

const StyledLanguageLink = styled(BaseLink).withConfig({shouldForwardProp: (prop, defaultValidatorFn) => (
  !['isMenuActive', 'isActive'].includes(prop) && defaultValidatorFn(prop)
)})`
  transition:
    opacity ${({theme}) => theme.global.duration} ease-in-out,
    color ${({theme}) => theme.global.duration} ease-in-out;
  opacity: 0.8;
  margin-right: 2.5rem;
  cursor: pointer;
  color: ${({theme}) => theme.colors.muted};

  ${({isMenuActive}) => isMenuActive && css`
    color: ${({theme}) => theme.colors.textInverted};
  `}

  &:hover,
  &:focus {
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: ${({theme}) => theme.global.underlineOffset};
  }

  ${({isActive}) => isActive && css`
    opacity: 1;
    text-decoration: underline;
    text-decoration-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.secondary};
    text-underline-offset: ${({theme}) => theme.global.underlineOffset};
    font-weight: bold;

    ${({isMenuActive}) => isMenuActive && css`
      color: ${({theme}) => theme.colors.textInverted};
      text-decoration-color: ${({theme}) => theme.colors.textInverted};
    `}

    &:hover,
    &:focus {
      text-decoration-color: ${({theme}) => theme.colors.primary};

      ${({isMenuActive}) => isMenuActive && css`
        text-decoration-color: ${({theme}) => theme.colors.textInverted};
      `}
    }
  `}

  ${media.down('mobile')(css`
    opacity: 0;
    pointer-events: none;

    ${({isMenuActive}) => isMenuActive && css`
      opacity: 1;
      pointer-events: all;
    `}
  `)}
`

const LanguageSwitcher = ({langToSlug, currentLang, isMenuActive, onMenuClick}) => {
  const allLanguages = map(languages)

  return (
    <StyledLinkContainer>
      <For each="language" of={allLanguages}>
        <StyledLanguageLink
            small
            key={language.key}
            to={langToSlug[language.key]}
            isActive={currentLang === language.key}
            isMenuActive={isMenuActive}
        >
          {language.label}
        </StyledLanguageLink>
      </For>
      <MenuButton
          active={isMenuActive}
          lang={currentLang}
          onClick={onMenuClick}
      />
    </StyledLinkContainer>
  )
}

LanguageSwitcher.propTypes = {
  langToSlug: PropTypes.object,
  currentLang: PropTypes.string,
  isMenuActive: PropTypes.bool,
  onMenuClick: PropTypes.func,
}

export default LanguageSwitcher
