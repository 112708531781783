import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import BaseLink from '../../atoms/BaseLink'


const StyledNavItem = styled.li`
  padding: 1.25rem 0;
  list-style: none;

  ${({nested}) => nested && css`
    padding: 0.75rem 0;
  `}
`

const StyledNavButton = styled('button').withConfig({shouldForwardProp: (prop, defaultValidatorFn) => (
  !['transparent'].includes(prop) && defaultValidatorFn(prop)
)})`
  padding: 0.25rem 0;
  border: 0;
  background: unset;
  cursor: pointer;
  color: ${({theme}) => theme.colors.textInverted};
  font-size: ${({theme}) => theme.components.typography.variants.a.large};
  font-weight: ${({theme}) => theme.components.typography.weight.semiBold};
  transition: opacity ${({theme}) => theme.global.duration} ease-in;

  ${({nested}) => nested && css`
    font-size: ${({theme}) => theme.components.typography.variants.a.normal};
  `}

  sup {
    vertical-align: super;
    padding: 0.5rem;
    font-size: 0.75em;
    opacity: 0.5;
  }

  &:hover,
  &:focus {
    opacity: 0.5;
  }
`

const chooseProps = (to, onClick) => {
  if (onClick) {
    return {
      type: 'button',
      onClick,
    }
  }

  return {
    to,
  }
}

const NavItem = ({nested, to, onClick, children, ...props}) => {
  const as = onClick ? 'button' : BaseLink

  return (
    <StyledNavItem nested={nested} {...props}>
      <StyledNavButton as={as} nested={nested} {...chooseProps(to, onClick)}>
        {children}
      </StyledNavButton>
    </StyledNavItem>
  )
}

NavItem.propTypes = {
  nested: PropTypes.bool,
  to: PropTypes.string,
  submenu: PropTypes.array,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default NavItem
