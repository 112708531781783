import PropTypes from 'prop-types'
import styled from 'styled-components'
import {media} from '../../../utils'


const StyledNavWrapper = styled.ul`
  padding-top: 7rem;
  width: 50%;
  pointer-events: all;

  &:last-child {
    ${media.down('mobile')(`
      display: none;
    `)}
  }

  ${media.down('mobile')(`
    width: 100%;
  `)}
`

const NavWrapper = ({children}) => {
  return (
    <StyledNavWrapper>
      {children}
    </StyledNavWrapper>
  )
}

NavWrapper.propTypes = {
  children: PropTypes.node,
}

export default NavWrapper
